import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../services/UserService";
import parseNestedUrl from "../utils/parseNestedUrl";

export function useOnboardingRedirect(configs: any) {
  const history = useNavigate();

  useEffect(() => {
    if (configs.flowModule.before.length > 0) {
      handleOnboardingRedirect(configs, history);
    }
  }, [configs.flowModule]);
}

function handleOnboardingRedirect(configs: any, history: any) {
  const { completedFlows } = UserService.getAttribute("userPreferences");
  const basePathname = parseNestedUrl(window.location.pathname).basePathname || "homepage";

  const matchingOnboardingPage = configs.flowModule.before.find(
    (module) => module.name === basePathname
  );

  if (!matchingOnboardingPage) {
    return;
  }

  const flowCompleted = completedFlows?.find(({ pageName }) => pageName === basePathname);
  const shouldRedirect =
    !flowCompleted?.before ||
    (flowCompleted?.before === true &&
      flowCompleted?.before_id !== matchingOnboardingPage?.before_id);

  if (shouldRedirect) {
    const redirectBase =
      matchingOnboardingPage.name === "homepage" ? "/homepage" : `/${matchingOnboardingPage.name}`;
    history(`${redirectBase}/0`, { replace: true });
  }
}

export default useOnboardingRedirect;
