/* eslint-disable */
import { useEffect, useState } from "react";

// Services
import i18n from "../i18n";

// Lib
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// Utils
import { handleAnalyticsTrack } from "../utils/Analytics";
import PageContent from "./ListingPageContent";
import { ShowBottomBar, ShowTopBar } from "../services/ToggleNativeBar";
import { useModal } from "../context/ModalContext";
import parseNestedUrl from "../utils/parseNestedUrl";

const ListingPage = () => {
  const { route_id } = useParams();
  const [pageContent, updatePageContent] = useState<any>([]);
  const [theme, updateTheme] = useState({});
  const { state } = useModal();

  // console.log(route_id);

  useEffect(() => {
    // Confirm if there's an after configuration.
    // if there is -> check modal state
    // if there is NOT -> then just show Bars(true)

    const { basePathname } = parseNestedUrl(window.location.pathname);
    const configExists = () => {
      const pageName = basePathname === "/" ? "homepage" : basePathname;

      const pageConfig = window.config?.pages?.[pageName];
      const flows = window.config?.flows;

      return pageConfig?.flow.some(
        (flow_reference) => flows?.[flow_reference]?.triggerEvent === "after"
      );
    };

    if (configExists()) {
      // console.log(state);
      ShowTopBar(!state.isOpen);
      ShowBottomBar(!state.isOpen);
    } else if (basePathname === "homepage") {
      ShowTopBar(true);
      ShowBottomBar(true);
    }
  }, [state]);

  useEffect(() => {
    // To reset scroll position on page transition
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    let tempRoutename = route_id;
    // Get the theme Object

    updateTheme(window.config.theme);
    if (window.config) {
      if (route_id) {
        updatePageContent(window.config.pages?.[route_id]);
      } else if (window.config.pages?.homepage) {
        tempRoutename = "homepage";
        updatePageContent(window.config.pages.homepage);
      }
    }
    // console.log(tempRoutename);
    handleAnalyticsTrack(tempRoutename || "No Route Name", "onLoad_event", "");
  }, [route_id]);

  return (
    <>
      <Helmet title={i18n.t(pageContent.pageTitle)} />
      <PageContent json={pageContent} theme={theme} pageName={route_id} />
    </>
  );
};

export default ListingPage;
